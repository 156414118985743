import * as React from "react"
import { Helmet } from "react-helmet"
import 'normalize.css'
import '../../components/layout.scss'
import '../../components/animations.scss'
import Layout from "../../components/layout"
import Nav from "../../components/nav"
import Section from "../../components/section"
import Wrapper from "../../components/wrapper"
import Column from "../../components/column"
import Title from "../../components/title"
import Text from "../../components/text"
import Button from "../../components/button"
import Divider from "../../components/divider"
import GalleryItem from "../../components/galleryItem"
import SocialIcons from "../../components/socialIcons"
import { StaticImage } from "gatsby-plugin-image"
import Dot from '../../images/dot.svg'
import ButtonWrapper from "../../components/buttonWrapper"


// markup
const IndexPage = () => {

  return (
    <Layout>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Juraj Weszter – UI/UX Web and Application Design, Branding</title>
        <link rel="canonical" href="https://jurajweszter.com/projects/maily" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@500&amp;family=Be+Vietnam+Pro:wght@300;400;500&amp;display=swap" rel="stylesheet" />
      </Helmet>
     
      <Nav return />

      <Section pt='4rem' pb='4rem'>
        <Wrapper reversing='true' content='columns' columnCount='1'>
          <Column width='100' spacing='500' shift='075em'>
            <Title h1 size='900' maxWidth='15' revealOnScroll>
                Maily, an alternative to e-mail and instant messaging
            </Title>
            <Text size='900' maxWidth='50' revealOnScroll delay='150ms'>
                Maily makes e-mail communication faster and more comfortable, ensuring that your e-mail messages are received and read instantly. Aiming to make e-mail as easy to use as instant messaging, Maily is uniquely positioned to replace social media as the preferred communication method.
            </Text>
          </Column>
        </Wrapper>
      </Section>

      <Section pt='3rem' pb='5rem'>
        <Wrapper content='gallery'>
          <GalleryItem span='half' href=''>
            <StaticImage layout='fullWidth' src={'../../images/previews/maily/1.png'} />
          </GalleryItem>
          <GalleryItem span='half' href=''>
            <StaticImage layout='fullWidth' src={'../../images/previews/maily/2.png'} />
          </GalleryItem>
          <GalleryItem span='full' href=''>
            <StaticImage layout='fullWidth' src={'../../images/previews/maily/6.png'} />
          </GalleryItem>
          <GalleryItem span='full' href=''>
            <StaticImage layout='fullWidth' src={'../../images/previews/maily/5.png'} />
          </GalleryItem>
          <GalleryItem span='full' href=''>
            <StaticImage layout='fullWidth' src={'../../images/previews/maily/mockup--0.png'} />
          </GalleryItem>
        </Wrapper>
      </Section>

      <Section pt='3.5rem' pb='5rem' id='contact'>
        <Wrapper content='columns' columnCount='1'>
          <Column align='center' spacing='500'>
            <StaticImage src='../../images/portrait--400.png' placeholder='none' backgroundColor='rgb(28, 28, 32)' className='image image--portrait' imgStyle={{ borderRadius: '100%'}} />
            <Title size='700' maxWidth='13' revealOnScroll>
              Let's design your website, application, or digital project
            </Title>
            <Text size='900' maxWidth='24' revealOnScroll delay='150ms'>
              Call or leave a message — we'll discuss your project and see if we're a good fit.
            </Text>
            <ButtonWrapper revealOnScroll delay='300ms'>
              <Button href='mailto:jweszter@gmail.com' buttonStyle='basic' size='500'>
                jweszter@gmail.com
              </Button>
              <Button href='tel:+421902691212' buttonStyle='basic' size='500' background='charcoal'>
                <img src={Dot} alt='' class='button__icon button__icon--dot'/>
                +421 902 691 212
              </Button>
            </ButtonWrapper>
          </Column>
        </Wrapper>
      </Section>

      <Section pt='3rem' pb='3rem'>
        <Divider />
      </Section>

      <Section pt='2rem' pb='6rem'>
        <Wrapper content='columns' columnCount='3' stretch='true'>
          <Column spacing='600' width='100' mobileAlign='center'>
            <Title size='50'>
              Ing. Juraj Weszter
            </Title>
            <Text size='300'>
              IČO 11747978<br />
              Nové Sady 988/2, 602 00 Brno<br />
              Nejsem plátcem DPH.
            </Text>
          </Column>
          <Column align='center' spacing='600' width='100'>
            <Text size='300'>
              Fyzická osoba zapsaná v Živnostnenském rejstříku Magistrátu města Brna.
            </Text>
            <SocialIcons />
          </Column>
          <Column align='right' spacing='600' width='100' mobileAlign='center'>
            <Text size='300'>
              E-mail
            </Text>
            <Title size='50'>
              jweszter@gmail.com
            </Title>
            <Text size='300'>
              Phone / WhatsApp
            </Text>
            <Title size='50'>
              +421 902 691 212
            </Title>
          </Column>
        </Wrapper>
      </Section>

    </Layout>
  )
}

export default IndexPage
